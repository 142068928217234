//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import Tomb from "../classes/Tomb";

export const ModuleEvents = {
	init: "tomb_init",
	data: "tomb_data",
	user: "tomb_user",
};

export class Module_Tomb extends DAppModule
{
	static moduleName = "tomb";
	constructor()
	{
		super(m => m.tomb);
		this.tomb = null;
	}

	async onRefreshChainData(dApp)
	{
		if (this.tomb === null)
		{
			return;
		}

		//data
		await MLUtils.measureTime(`TM: Tomb => Data`, async () =>
		{
			await this.tomb.batch_data();
		});

		//user
		await MLUtils.measureTime(`TM: Tomb => User`, async () =>
		{
			await this.tomb.batch_userData();
		});
	}

	async onLoad(dApp)
	{
		if (!Module_Tomb.availableOnChain(dApp.currentChain)) {
			return;
		}

		const tomb = dApp.currentChain.modules.tomb;
		dApp.log(false, `Loading Tomb`);
		this.tomb = new Tomb(tomb);
		this.setLoaded();
	}

	static availableOnChain(chain)
	{
		return (chain?.modules?.tomb !== undefined);
	}
}

export const useTomb = () => useModule(Module_Tomb);

const currentModule = new Module_Tomb();
export default currentModule;
