import { useState } from "react";

//components
import {
	Text,
	Flex,
	Grid,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	PageHeader,
	PageContent,
	ErrorServiceNotAtChain,
	InfoModuleLoading,
	Button,
	TokenIcon,
	FormatFiatAmount,
	ValueTokenAmount,
	ValueTokenFiatAmount,
	FormatPercent,
	useDApp,
} from "@MoonLabsDev/dapp-core-lib";
import ModalDepositToVault from "src/dApp/components/MasterChef/ModalDepositToVault.js";
import ModalWithdrawFromVault from "src/dApp/components/MasterChef/ModalWithdrawFromVault.js";

//framework
import { Events } from "@MoonLabsDev/dapp-core-lib";

//hooks
import {
	useWeb3Connection,
	useEventSubscription,
} from "@MoonLabsDev/dapp-core-lib";

//module
import {
	useMasterChef,
	Module_MasterChef,
	ModuleEvents,
} from "src/dApp/modules/Module_MasterChef";

//styles
import styles from "src/pages/Pages.module.css";

const flexColumn = { display: "flex", flexDirection: "column" };

const ModalButton = ({ setShow, modal, children, disabled }) => {
	return (
		<>
			<Button
				onClick={() => !disabled && setShow(true)}
				disabled={disabled}
			>
				{children}
			</Button>
			{modal}
		</>
	);
};

const VaultStat = (props) => {
	return (
		<Flex style={{ justifyContent: "space-between" }}>
			<Text>{props.text}</Text>
			<Flex>{props.children}</Flex>
		</Flex>
	);
};

const VaultsCard = (props) => {
	//context
	const chef = useMasterChef();

	//functions
	const loadFarm = (id) => {
		return { ...chef.findFarm(id) };
	};

	//state
	const [farm, setFarm] = useState(loadFarm(props.farmID));
	const [showDepositModal, setShowDepositModal] = useState(() => false);
	const [showWithdrawModal, setShowWithdrawModal] = useState(() => false);
	const [showZapModal, setShowZapModal] = useState(() => true);

	//handler
	const handleClaim = () => {
		if (farm.userPending.isZero()) {
			return;
		}
		chef.claim(farm.id).send();
	}

	//effects
	useEventSubscription([ModuleEvents.data, ModuleEvents.user], () =>
		setFarm(loadFarm(props.farmID))
	);

	return (
		<Card className={styles.vault} style={{ width: "300px" }}>
			<CardHeader>
				<Flex>
					<TokenIcon
						className={styles.vaultStatIcon}
						token={farm.depositToken}
						style={{ margin: "2px" }}
					/>
					<Text size={-1}>{farm.name}</Text>
				</Flex>
			</CardHeader>
			<CardBody
				style={{
					...flexColumn,
					minHeight: "200px",
				}}
			>
				<Text size={-1} style={{ padding: "2px 0px" }}>
					<Grid>
						<Text color={3} size={0}>
							Pool info:
						</Text>
						<VaultStat text="TVL:">
							<FormatFiatAmount
								value={farm.totalDepositUSD}
								shorten={true}
								precision={0}
							/>
						</VaultStat>
						<VaultStat text="APR:">
							<FormatPercent value={farm.apr} smart={true} />
						</VaultStat>
						<VaultStat text="Daily APR:">
							<FormatPercent value={farm.dailyAPR} smart={true} />
						</VaultStat>
						<Text color={3} size={0}>
							User Data:
						</Text>
						<VaultStat text="Deposit amount:">
							<Text color={1}>
								<ValueTokenAmount
									token={farm.depositToken}
									value={farm.userDeposit}
									load={true}
									additionalLoad={chef.initializedUser}
									shorten={true}
								/>
							</Text>
							<Text>
								(
								<ValueTokenFiatAmount
									token={farm.depositToken}
									value={farm.userDeposit}
									load={true}
									additionalLoad={farm.initializedUser}
									precision={2}
								/>
								)
							</Text>
						</VaultStat>
						<VaultStat text="Pending rewards:">
							<Text color={1}>
								<ValueTokenAmount
									token={chef.rewardToken}
									value={farm.userPending}
									smart={true}
								/>
							</Text>
							<Text>
								(
								<ValueTokenFiatAmount
									token={chef.rewardToken}
									value={farm.userPending}
									load={true}
									additionalLoad={farm.initializedUser}
									precision={2}
								/>
								)
							</Text>
						</VaultStat>
					</Grid>
				</Text>
			</CardBody>
			<CardFooter>
				{farm.depositToken.checkApproved(chef.address) ? (
					<Grid cols={(showZapModal && farm.depositToken.isLPToken()) ? 3 : 2}>
						<ModalButton
							setShow={setShowWithdrawModal}
							modal=
							{
								<ModalWithdrawFromVault
									show={showWithdrawModal}
									farm={farm}
									onClose={() => setShowWithdrawModal(false)}
								/>
							}
						>
							-
						</ModalButton>
						{(showZapModal && farm.depositToken.isLPToken()) &&
							<Button
								href={`https://app.uniswap.org/#/add/v2/${farm.depositToken.token0}/${farm.depositToken.token1}`}
							>
								⚡
							</Button>
						}
						<ModalButton
							setShow={setShowDepositModal}
							modal=
							{
								<ModalDepositToVault
									show={showDepositModal}
									farm={farm}
									onClose={() => setShowDepositModal(false)}
								/>
							}
						>
							+
						</ModalButton>
					</Grid>
				) : (
					<Grid>
						<Button
							onClick={() =>
								farm.depositToken.approve(chef.address).send()
							}
						>
							Approve
						</Button>
					</Grid>
				)}
				<Grid style={{ paddingTop: "8px" }}>
					<Button
						disabled={farm.userPending.isZero()}
						onClick={() => handleClaim()}
					>
						Claim
					</Button>
				</Grid>
			</CardFooter>
		</Card >
	);
};

const VaultOverview = () => {
	//context
	const chef = useMasterChef();

	//state
	const [count, setCount] = useState(chef.farms.length);

	//effects
	useEventSubscription(ModuleEvents.init, () => setCount(chef.farms.length));

	return (
		<Flex>
			<Grid
				gap="30px"
				style={{ alignItems: "start" }}
				responsive={{
					xxl: {
						cols: 4,
					},
					md: {
						cols: 2,
					},
					sm: {
						cols: 1,
					},
				}}
			>
				{chef.farms.map((f, index) => (
					<VaultsCard key={index} farmID={f.id} />
				))}
			</Grid>
		</Flex>
	);
};

export default () => {
	//context
	const web3 = useWeb3Connection();

	//state
	const [walletProvider, setWalletProvider] = useState(
		() => web3.walletProvider ?? "No Wallet"
	);

	//effect
	useEventSubscription(Events.web3.update, () =>
		setWalletProvider(web3.walletProvider ?? "No Wallet")
	);

	return (
		<>
			<PageHeader title="Vaults">
				Earn SGLDM by staking uniswap LP
			</PageHeader>

			<PageContent>
				<ErrorServiceNotAtChain module="masterChef">
					<InfoModuleLoading module="masterChef">
						<VaultOverview />
					</InfoModuleLoading>
				</ErrorServiceNotAtChain>
			</PageContent>
		</>
	);
}
