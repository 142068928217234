//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction, MLUtils  } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_Olympus";

//contracts
import ABI_OlympusBond from "../abi/OlympusBond";
import ABI_OlympusTreasury from "../abi/OlympusTreasury";

class Olympus {
	////////////////////////////////////

	constructor(_data) {
		this.initialized = false;

		//base values
		this.address = _data.bond;
		this.addressTreasury = _data.treasury;
		this.payoutTokenAddress = _data.payoutToken;
		this.baseTokenAddress = _data.baseToken;
		this.principalTokenAddress = _data.principalToken;
		this.percentFactor = 10000;

		//tokens
		this.payoutToken = DApp.instance.findToken(this.payoutTokenAddress);
		this.baseToken = DApp.instance.findToken(this.baseTokenAddress);
		this.principalToken = DApp.instance.findToken(
			this.principalTokenAddress
		);

		//info
		this.treasuryBalance = MLWeb3.toBN(0);
		this.treasuryBalanceUSD = MLWeb3.toBN(0);
		this.olympusBalance = MLWeb3.toBN(0);
		this.userPercentVested = MLWeb3.toBN(0);
		this.userPending = MLWeb3.toBN(0);
		this.currentDebt = MLWeb3.toBN(0);
		this.debtRatio = 0;
		this.trueBondPrice = MLWeb3.toBN(0);
		this.totalPurchased = MLWeb3.toBN(0);
		this.maxPayout = MLWeb3.toBN(0);
		this.bondPriceUSD = MLWeb3.toBN(0);
		this.roi = 0;
		this.debtLimitReached = false;
	}

	////////////////////////////////////

	debugErrorString(_text) {
		return `Olympus failed at: ${_text}`;
	}

	getContract(_user) {
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_OlympusBond, this.address).methods;
	}

	makeMultiCall(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.address,
			ABI_OlympusBond,
			_calls
		);
	}

	makeMultiCallTreasury(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.addressTreasury,
			ABI_OlympusTreasury,
			_calls
		);
	}

	dispatchEvent(_name) {
		document.dispatchEvent(
			new CustomEvent(_name, {
				detail: {
					address: this.address,
				},
			})
		);
	}

	/////////////////////////
	// Data
	/////////////////////////

	async batch_data() {
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_data(),
			(o, r) => o.processRequest_data(r),
			false,
			"[Olympus] batch data",
			"Olympus: data"
		);
	}

	makeRequest_data() {
		return [
			this.makeMultiCall({
				currentDebt: { function: "currentDebt" },
				debtRatio: { function: "debtRatio" },
				trueBondPrice: { function: "trueBondPrice" },
				totalPrincipalBonded: { function: "totalPrincipalBonded" },
				maxPayout: { function: "maxPayout" },
			}),
			this.payoutToken.makeMultiCall({
				treasuryBalance: {
					function: "balanceOf",
					parameters: [this.addressTreasury],
				},
			}),
			this.payoutToken.makeMultiCall({
				olympusBalance: {
					function: "balanceOf",
					parameters: [this.address],
				},
			}),
		];
	}

	async processRequest_data(_data)
	{
		this.currentDebt = MLWeb3.toBN(_data.currentDebt);
		this.debtRatio = MLWeb3.convertTokenBN_Float(_data.debtRatio, this.payoutToken);
		this.trueBondPrice = MLWeb3.toBN(_data.trueBondPrice).mul(MLWeb3.toBN(10).pow(MLWeb3.toBN(this.principalToken.decimals - 7)));
		this.totalPurchased = MLWeb3.toBN(_data.totalPrincipalBonded);
		this.maxPayout = MLWeb3.toBN(_data.maxPayout);
		this.treasuryBalance = MLWeb3.toBN(_data.treasuryBalance);
		this.olympusBalance = MLWeb3.toBN(_data.olympusBalance);

		//process
		this.bondPriceUSD = this.principalToken.getPriceUSDForAmount(this.trueBondPrice);
		if (!this.bondPriceUSD.isZero())
		{
			this.roi = MLWeb3.getPercent(this.payoutToken.getPriceUSD(), this.bondPriceUSD);
		}
		this.bondDiscount = 1 - MLWeb3.getPercent(this.bondPriceUSD, this.payoutToken.getPriceUSD());
		this.debtLimitReached = this.currentDebt > this.maxPayout;

		//complete
		this.dispatchEvent(ModuleEvents.data);
	}

	/////////////////////////
	// User
	/////////////////////////

	async batch_userData() {
		if (DApp.instance.account === null) {
			return;
		}
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_userData(),
			(o, r) => o.processRequest_userData(r),
			false,
			"[Olympus] batch userData",
			"Olympus: userData"
		);
	}

	makeRequest_userData() {
		return this.makeMultiCall({
			userPercentVested: { function: "percentVestedFor", parameters: [DApp.instance.account] },
			userPending: { function: "pendingPayoutFor", parameters: [DApp.instance.account] },
		});
	}

	async processRequest_userData(_data) {
		this.userPercentVested = parseFloat(MLWeb3.toBN(_data.userPercentVested).toString(10)) / this.percentFactor;
		this.userPending = MLWeb3.toBN(_data.userPending);

		//complete
		this.dispatchEvent(ModuleEvents.user);
	}

	/////////////////////////
	// Transactions
	/////////////////////////

	buyBonds(_amount, _slippage = 1) {
		const maxPrice = MLWeb3.getBNPercentShare(this.trueBondPrice, _slippage);
		const con = this.getContract();
		return new Web3Transaction(
			con.deposit(
				_amount,
				maxPrice,
				DApp.instance.account
			),
			this.debugErrorString("buyBonds"),
			`Buy Bonds ${MLFormat.smartFormatToken(_amount, this.payoutToken)}`
		);
	}

	redeemBonds() {
		const con = this.getContract();
		return new Web3Transaction(
			con.redeem(DApp.instance.account),
			this.debugErrorString("redeemBonds"),
			`Redeem OlympusBonds`
		);
	}

	////////////////////////////////////
}

export default Olympus;
