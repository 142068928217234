import { useState, useEffect } from "react";

//components
import {
	Flex,
	Button,
	Modal
} from "@MoonLabsDev/dapp-core-lib";
import InputTokenAmount_FarmBalance from "src/dApp/components/MasterChef/InputTokenAmount_FarmBalance.js";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//styles


//module
import {
	useMasterChef
} from "src/dApp/modules/Module_MasterChef";
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

const ModalWithdrawFromVault = (props) => {
	//context
	const dApp = useDApp();
	const chef = useMasterChef();

	//state
	const [show, setShow] = useState(() => props.show);
	const [withdrawAmount, setWithdrawAmount] = useState("0");

	//functions

	//handler
	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		} else {
			setShow(false);
		}
	};

	//effect
	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	return (
		<Modal
			show={show}
			allowBig={true}
			header={`Withdraw ${props.farm.name}`}
			footer={
				<Button
					disabled={props.farm.userDeposit.isZero()}
					onClick={() => {
						if (!withdrawAmount) return;
						chef.withdraw(
							props.farm.id,
							MLWeb3.convertFloatString_BN(withdrawAmount, props.farm.depositToken))
							.trySend()
					}
					}
				>
					Withdraw
				</Button>
			}
			onClose={() => handleClose()}
		>
			<Flex>
				<InputTokenAmount_FarmBalance
					farmID={props.farm.id}
					token={props.farm.depositToken}
					value={withdrawAmount}
					onChange={(event, value) => setWithdrawAmount(value)}
				/>
			</Flex>
		</Modal>
	);
};

export default ModalWithdrawFromVault;
