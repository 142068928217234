export default [
	{
		inputs: [
			{ internalType: "address", name: "_payoutToken", type: "address" },
			{ internalType: "address", name: "_initialOwner", type: "address" },
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "bondContract",
				type: "address",
			},
		],
		name: "BondContractDewhitelisted",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "bondContract",
				type: "address",
			},
		],
		name: "BondContractWhitelisted",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "destination",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "Withdraw",
		type: "event",
	},
	{
		inputs: [{ internalType: "address", name: "", type: "address" }],
		name: "bondContract",
		outputs: [{ internalType: "bool", name: "", type: "bool" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "_bondContract", type: "address" },
		],
		name: "dewhitelistBondContract",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "payoutToken",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "policy",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_amountPayoutToken",
				type: "uint256",
			},
		],
		name: "sendPayoutTokens",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "_newOwner", type: "address" },
		],
		name: "transferManagment",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_principalTokenAddress",
				type: "address",
			},
			{ internalType: "uint256", name: "_amount", type: "uint256" },
		],
		name: "valueOfToken",
		outputs: [{ internalType: "uint256", name: "value_", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "_bondContract", type: "address" },
		],
		name: "whitelistBondContract",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "_token", type: "address" },
			{ internalType: "address", name: "_destination", type: "address" },
			{ internalType: "uint256", name: "_amount", type: "uint256" },
		],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
];
