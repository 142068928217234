import { useState, useEffect, useRef } from "react";

//components{}
import {
	Flex,
	Button,
	Modal,
	InputTokenAmount_Balance
} from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//styles


//module
import {
	useTomb
} from "src/dApp/modules/Module_Tomb";
import { MLWeb3, MLFormat } from "@MoonLabsDev/dapp-core-lib";


const ModalDepositToBoardroom = (props) => {
	//context
	const dApp = useDApp();
	const tomb = useTomb();

	//state
	const [show, setShow] = useState(() => props.show);
	const [depositAmount, setdepositAmount] = useState("0");

	//functions

	//handler
	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		} else {
			setShow(false);
		}
	};

	//effect
	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	return (
		<Modal
			show={show}
			allowBig={true}
			header={`Stake SGLDM`}
			footer={
				<Button onClick={() => {
					if (!depositAmount) return;
					tomb.stakeShares(
						MLWeb3.convertFloatString_BN(depositAmount, tomb.shareToken))
						.send()
				}
				}
				>
					Deposit
				</Button>
			}
			onClose={() => handleClose()}
		>
			<Flex>
				<InputTokenAmount_Balance
					token={tomb.shareToken}
					allowTokenSelection={true}
					value={depositAmount}
					onChange={(event, value) => setdepositAmount(value)}
				/>
			</Flex>
		</Modal>
	);
};

export default ModalDepositToBoardroom;
