import { useState } from "react";

//components
import {
	Text,
	Flex,
	Grid,
	Card,
	CardBody,
	CardFooter,
	PageHeader,
	PageContent,
	ErrorServiceNotAtChain,
	InfoModuleLoading,
	Button,
	ValueTokenFiatAmount,
	ValueTokenPrice,
	ValueTokenAmount,
	FormatPercent,
} from "@MoonLabsDev/dapp-core-lib";

//framework
import ModalBuyOlympusBonds from "src/dApp/components/Olympus/ModalBuyOlympusBonds.js";

//hooks
import {
	useEventSubscription,
} from "@MoonLabsDev/dapp-core-lib";

//module
import {
	useOlympus,
	Module_Olympus,
	ModuleEvents,
} from "src/dApp/modules/Module_Olympus";

const flexColumn = { display: "flex", flexDirection: "column" };
const wrap = { display: "flex", flexWrap: "wrap" };
const contentCenter = {
	display: "flex",
	alignItems: "center",
};

const filler = {
	name: "Bond GoldMint",
	icon: "I m a logo", // fill
	description: `BGLDM is a Bond token and one of the three tokens available in the GoldMint Finance ecosystem, Bonds allow GLDM supply to deflate when below peg which helps drive price back up and can be redeemed for 1.1 or more GLDM when above peg, as well as utilized throughout our ecosystem`,
	token: "BGLDM",
	network: "eth",
};

const DataItem = (props) => {
	return (
		<Flex style={{ ...flexColumn, minWidth: "100px" }}>
			<Text>
				{props.title}
			</Text>
			{props.children}
		</Flex>
	);
}

const OlympusContent = () => {
	//context
	const olympus = useOlympus();

	//functions
	const getOlympusData = () => {
		return {
			treasuryBalance: olympus.treasuryBalance,
			totalBonds: olympus.totalPurchased,
			roi: olympus.roi,
			discount: olympus.bondDiscount,
			vested: olympus.userPercentVested,
			pending: olympus.userPending,
			maxPayout: olympus.maxPayout
		};
	};

	const handleClaim = () => {
		if (olympusData.pending.isZero()) return;
		olympus.redeemBonds().trySend()
	}

	const generateBuyModalButton = (_setShow, _modal) => {
		return (
			<>
				<Button
					style={{ width: "150px" }}
					disabled={olympusData.pending.isZero()}
					onClick={() => _setShow(true)}>
					Buy Bonds
				</Button>
				{_modal}
			</>
		);
	};
	//state
	const [olympusData, setOlympusData] = useState(() => getOlympusData());
	const [showBuyModal, setShowBuyModal] = useState(() => false);

	//effects
	useEventSubscription([ModuleEvents.data, ModuleEvents.user], () =>
		setOlympusData(getOlympusData())
	);

	return (
		<Flex style={{ width: "100%" }}>
			<Card style={{ width: "80%" }}>
				<CardBody
					style={{
						minHeight: "200px",
					}}
				>
					<Text size={-1}>
						<Flex>
							<Grid cols={2} gap={"50px"} style={wrap}>
								<div style={flexColumn}>
									<Text size={1} style={{ margin: "10px" }}>
										{filler.name}
									</Text>
									<Text
										size={-1}
										style={{
											margin: "10px",
											maxWidth: "400px",
										}}
									>
										{filler.description}
									</Text>
									<Grid cols={3}>
										{/* <Image></Image>
						<Image></Image>
						<Image></Image> */}
									</Grid>
								</div>
								<Grid cols={2}>
									<Flex style={flexColumn}>
										<Text>Vesting period:</Text>
										<Text>7 Days</Text>
									</Flex>
									<Flex style={flexColumn}>
										<Text>Payout Token:</Text>
										<Text>{olympus.payoutToken.symbol}</Text>
									</Flex>
									<Flex style={flexColumn}>
										<Text>Market price:</Text>
										<ValueTokenPrice
											token={olympus.payoutToken}
											smart={true}
										/>
									</Flex>
									<Flex style={flexColumn}>
										<Text>Total bonds:</Text>
										<ValueTokenAmount
											token={olympus.payoutToken}
											value={olympusData.treasuryBalance}
											smart={true}
										/>
									</Flex>
								</Grid>
							</Grid>
						</Flex>
					</Text>
				</CardBody>
			</Card>
			<Card style={{ width: "80%" }}>
				<CardBody>
					<Text size={-1}>
						<Grid cols={6} gap={"40px"} style={wrap}>

							<DataItem
								title="Buy in Token"
							>
								<Text>{olympus.principalToken.symbol}</Text>
							</DataItem>

							<DataItem
								title="Discount"
							>
								<FormatPercent
									value={olympusData.discount}
									smart={true}
								/>
							</DataItem>

							<DataItem
								title="TBV"
							>
								<ValueTokenFiatAmount
									token={olympus.payoutToken}
									value={olympusData.treasuryBalance}
									precision={2}
								/>
							</DataItem>

							<DataItem
								title="Available to claim"
							>
								<ValueTokenAmount
									token={olympus.payoutToken}
									value={olympusData.pending}
									smart={true}
								/>
							</DataItem>

							<DataItem
								title="Amount vested"
							>
								<FormatPercent
									value={olympusData.vested < 1 ? olympusData.vested : 1}
									smart={true}
								/>
							</DataItem>
						</Grid>
					</Text>
				</CardBody>
				<CardFooter>
					<Grid cols={2} style={contentCenter}>
						<Button style={{ width: "150px" }} onClick={() => handleClaim()}>Claim</Button>
						{olympus.principalToken.checkApproved(olympus.address) ?
							<>
								{generateBuyModalButton(setShowBuyModal,
									<ModalBuyOlympusBonds
										show={showBuyModal}
										onClose={() => setShowBuyModal(false)} />)}
							</>
							: (
								<Button
									style={{ width: "150px" }}
									onClick={() =>
										olympus.principalToken.approve(olympus.address).send()
									}
								>
									Approve
								</Button>
							)
						}
					</Grid>
				</CardFooter>
			</Card>
		</Flex>
	);
};

export default () => {
	return (
		<>
			<PageHeader title="Olympus">
				Discounted Bond GoldMint Powered by Olympus Pro.
			</PageHeader>

			<PageContent>
				<ErrorServiceNotAtChain module="olympus">
					<InfoModuleLoading module="olympus">
						<OlympusContent />
					</InfoModuleLoading>
				</ErrorServiceNotAtChain>
			</PageContent>
		</>
	);
}
