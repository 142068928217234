import { useState, useEffect, useRef } from "react";

//components{}
import {
	Flex,
	Button,
	Modal,
	InputTokenAmount_Balance
} from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//styles


//module
import {
	useOlympus
} from "src/dApp/modules/Module_Olympus";
import { MLWeb3, MLFormat } from "@MoonLabsDev/dapp-core-lib";


const ModalBuyOlympusBonds = (props) => {
	//context
	const dApp = useDApp();
	// const tomb = useTomb();
	const olympus = useOlympus();

	//state
	const [show, setShow] = useState(() => props.show);
	const [depositAmount, setdepositAmount] = useState("0");

	//functions

	//handler
	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		} else {
			setShow(false);
		}
	};

	//effect
	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	return (
		<Modal
			show={show}
			allowBig={true}
			header={`Buy BGLDM`}
			footer={
				<Button
					disabled={!depositAmount}
					onClick={() => {
						if (!depositAmount) return;
						olympus.buyBonds(
							MLWeb3.convertFloatString_BN(depositAmount, olympus.principalToken))
							.trySend()
					}
					}
				>
					Buy Bonds
				</Button>
			}
			onClose={() => handleClose()}
		>
			<Flex>
				<InputTokenAmount_Balance
					token={olympus.principalToken}
					allowTokenSelection={true}
					value={depositAmount}
					onChange={(event, value) => setdepositAmount(value)}
				/>
			</Flex>
		</Modal>
	);
};

export default ModalBuyOlympusBonds;
