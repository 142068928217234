import { useState } from "react";

//components
import {
	Text,
	Flex,
	Grid,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	PageHeader,
	PageContent,
	ErrorServiceNotAtChain,
	InfoModuleLoading,
	Button,
	TokenIcon,
	FormatFiatAmount,
	ValueTokenAmount,
	ValueTokenFiatAmount
} from "@MoonLabsDev/dapp-core-lib";

//hooks
import {
	useEventSubscription,
} from "@MoonLabsDev/dapp-core-lib";

//module
import {
	useNodes,
	Module_Nodes,
	ModuleEvents,
} from "src/dApp/modules/Module_Nodes";

//styles
import styles from "src/pages/Pages.module.css";

const NodesStat = (props) => {
	return (
		<Flex style={{ justifyContent: "space-between" }}>
			<Text>{props.text}</Text>
			<Flex>{props.children}</Flex>
		</Flex>
	);
};

const NodesCard = (props) =>
{
	//context
	const nodes = useNodes();

	//functions
	const getNodeData = () =>
	{
		return {
			totalNodes: nodes.totalNodes,
			depositNodeLimit: nodes.depositNodeLimit,
			nodePrice: nodes.nodePrice,
			tvl: nodes.tvl,
			userDeposit: nodes.userDeposit,
			userClaimed: nodes.userClaimed,
			userPending: nodes.userPending,
			userNodes: nodes.userNodes[0]
		};
	};
	const handleClaim = () => {
		if (nodes.userPending.isZero()) {
			return;
		}
		nodes.claim().send();
	};

	//state
	const [nodeData, setNodeData] = useState(() => getNodeData());

	//effects
	useEventSubscription([ModuleEvents.data, ModuleEvents.user], () => setNodeData(getNodeData()));

	return (
		<Flex>
			<Card className={styles.vault} style={{ width: "300px" }}>
				<CardHeader>
					<Flex>
						<TokenIcon className={styles.vaultStatIcon} token={nodes.rewardToken} />
						<Text size={-1} >{nodes.rewardToken.getFullName()}</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<Text size={-1} style={{ padding: "2px 0px" }}>
						<Grid>
							<Text color={3} size={0}>
								Node info:
							</Text>

							<NodesStat text="APR (daily):">
								<Text>547,5%</Text>
								<Text>(1.5%)</Text>
							</NodesStat>
							<NodesStat text="Nodes count:">
								{nodeData.totalNodes} / {nodeData.depositNodeLimit}
							</NodesStat>
							<NodesStat text="Price:">
								<ValueTokenAmount
									token={nodes.rewardToken}
									value={nodeData.nodePrice}
									load={true}
									smart={true}
								/>
								<Text>{nodes.rewardToken.getFullName()}</Text>
								(
								<ValueTokenFiatAmount
									token={nodes.rewardToken}
									value={nodeData.nodePrice}
									load={true}
									smart={true}
								/>
								)
							</NodesStat>
							<NodesStat text="TVL:">
								<FormatFiatAmount
									value={nodeData.tvl}
									precision={2}
								/>
							</NodesStat>

							<Text color={3} size={0}>
								User Data:
							</Text>
							<NodesStat text="Node count:">
								<span>{nodeData.userNodes}</span>
								(
								<ValueTokenFiatAmount
									token={nodes.rewardToken}
									value={nodeData.userDeposit}
									load={true}
									smart={true}
								/>
								)
							</NodesStat>
							<NodesStat text="Claimed amount:">
								<ValueTokenAmount
									token={nodes.rewardToken}
									value={nodeData.userClaimed}
									load={true}
									smart={true}
								/>
								{" "}(
								<ValueTokenFiatAmount
									token={nodes.rewardToken}
									value={nodeData.userClaimed}
									load={true}
									smart={true}
								/>
								)
							</NodesStat>

							<Text color={3} size={0}>
								Pending rewards:
							</Text>

							<NodesStat text="Pending:">
								<div style={{ paddingRight: "2px" }}>
									<ValueTokenAmount
										token={nodes.rewardToken}
										value={nodeData.userPending}
										load={true}
										smart={true}
									/>
								</div>
								(
								<ValueTokenFiatAmount
									token={nodes.rewardToken}
									value={nodeData.userPending}
									load={true}
									smart={true}
								/>
								)
							</NodesStat>
						</Grid>
					</Text>
				</CardBody>
				<CardFooter>
					<Grid>
						{nodeData.totalNodes < nodeData.depositNodeLimit &&
							<Grid>
								{nodes.rewardToken.checkApproved(nodes.address) ?
									(
										<Grid cols={2}>
											<Button>
												Buy
											</Button>
											<Button>Compound</Button>
										</Grid>
									) : (
										<Grid>
											<Button onClick={() => nodes.depositToken.approve(nodes.address).send()}>
												Approve
											</Button>
										</Grid>
									)}
							</Grid>
						}
						<Grid style={{ width: "auto" }}>
							<Button
								disabled={nodes.userPending.isZero()}
								onClick={() => handleClaim()}>Claim</Button>
						</Grid>
					</Grid>
				</CardFooter>
			</Card>
		</Flex>
	);
};

export default () => {
	return (
		<>
			<PageHeader title="Nodes">Generate GLDM with nodes</PageHeader>

			<PageContent>
				<ErrorServiceNotAtChain module="nodes">
					<InfoModuleLoading module="nodes">
						<NodesCard />
					</InfoModuleLoading>
				</ErrorServiceNotAtChain>
			</PageContent>
		</>
	);
}
