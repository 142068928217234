//framework
import { DApp, MLWeb3, MLMultiCall, Web3Transaction, MLUtils  } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_Nodes";

//contracts
import ABI_Nodes from "../abi/Nodes";
import ABI_NodesV2 from "../abi/NodesV2";

class Nodes
{
	////////////////////////////////////

	constructor(_data)
	{
		this.initialized = false;
		this.initializedData = false;
		this.initializedUser = false;

		//base values
		this.address = _data.address;
		this.addressV2 = _data.v2;

		//data
		this.depositTokenAddress = _data.depositToken;
		this.rewardTokenAddress = _data.rewardToken;
		this.tvl = MLWeb3.toBN(0);

		//runtime
		this.depositNodeLimit = 0;
		this.rewardNodeLimit = 0;
		this.totalAllocPoint = MLWeb3.toBN(0);
		this.nodePrice = MLWeb3.toBN(0);
		this.totalDeposit = MLWeb3.toBN(0);
		this.totalClaimed = MLWeb3.toBN(0);
		this.totalRewards = MLWeb3.toBN(0);
		this.totalUsers = MLWeb3.toBN(0);
		this.totalNodes_0 = 0;
		this.totalNodes_1 = 0;
		this.totalNodes_2 = 0;
		this.totalDepositUSD = MLWeb3.toBN(0);
		this.totalNodes = 0;
		this.userAllocPoints = MLWeb3.toBN(0);
		this.dayDrip = MLWeb3.toBN(0);
		this.userPending = MLWeb3.toBN(0);
		this.userDeposit = MLWeb3.toBN(0);
		this.userClaimed = MLWeb3.toBN(0);
		this.userNodes = [];

		//tokens
		this.depositToken = DApp.instance.findToken(this.depositTokenAddress);
		this.rewardToken = DApp.instance.findToken(this.rewardTokenAddress);
		this.depositToken.checkApproved(this.address);
		this.rewardToken.checkApproved(this.address);
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `Nodes failed at: ${_text}`;
	}

	getContract(_user)
	{
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_Nodes, this.address).methods;
	}

	makeMultiCall(_calls)
	{
		return MLMultiCall.makeMultiCallContext(
			this.address,
			ABI_Nodes,
			_calls
		);
	}

	getContractV2(_user)
	{
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_NodesV2, this.addressV2).methods;
	}

	makeMultiCallV2(_calls)
	{
		return MLMultiCall.makeMultiCallContext(
			this.addressV2,
			ABI_NodesV2,
			_calls
		);
	}

	dispatchEvent(_name)
	{
		MLUtils.dispatchEvent(_name);
	}

	/////////////////////////
	// Data
	/////////////////////////

	async batch_init()
	{
		if (this.initialized)
		{
			return;
		}
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_init(),
			(o, r) => o.processRequest_init(r),
			false,
			"[Nodes] batch init",
			"Nodes: init"
		);
	}

	makeRequest_init()
	{
		return [
			this.makeMultiCall(
			{
				depositNodeLimit: { function: "gldmNodesLimit" },
				rewardNodeLimit: { function: "bgldmNodesLimit" },
				totalAllocPoint: { function: "totalAllocPoints" },
				nodePrice: { function: "tierAmounts", parameters: [0] },
			}),
		];
	}

	async processRequest_init(_data)
	{
		this.depositNodeLimit = parseInt(_data.depositNodeLimit);
		this.rewardNodeLimit = parseInt(_data.rewardNodeLimit);
		this.totalAllocPoint = MLWeb3.toBN(_data.totalAllocPoint);
		this.nodePrice = MLWeb3.toBN(_data.nodePrice);

		//complete
		this.initialized = true;
		this.dispatchEvent(ModuleEvents.init);
	}

	/////////////////////////
	// Data
	/////////////////////////

	async batch_data()
	{
		if (!this.initialized)
		{
			await this.batch_init();
		}
		if (!this.initialized)
		{
			return;
		}

		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_data(),
			(o, r) => o.processRequest_data(r),
			false,
			"[Nodes] batch data",
			"Nodes: data"
		);
	}

	makeRequest_data()
	{
		return [
			this.makeMultiCall(
			{
				totalDeposit: { function: "total_deposited" },
				totalClaimed: { function: "total_claimed" },
				totalRewards: { function: "total_rewards" },
				totalUsers: { function: "total_users" },

				totalNodes_0: { function: "totalNodes", parameters: [0] },
				totalNodes_1: { function: "totalNodes", parameters: [1] },
				totalNodes_2: { function: "totalNodes", parameters: [2] }
			}),
		];
	}

	async processRequest_data(_data)
	{
		this.totalDeposit = MLWeb3.toBN(_data.totalDeposit);
		this.totalClaimed = MLWeb3.toBN(_data.totalClaimed);
		this.totalRewards = MLWeb3.toBN(_data.totalRewards);
		this.totalUsers = MLWeb3.toBN(_data.totalUsers);
		this.totalNodes_0 = parseInt(_data.totalNodes_0);
		this.totalNodes_1 = parseInt(_data.totalNodes_1);
		this.totalNodes_2 = parseInt(_data.totalNodes_2);

		//process
		this.totalDepositUSD = this.depositToken.getPriceUSDForAmount(this.totalDeposit);
		this.tvl = this.totalDepositUSD;
		this.totalNodes = this.totalNodes_0 + this.totalNodes_1 + this.totalNodes_2;

		//complete
		this.initializedData = true;
		this.dispatchEvent(ModuleEvents.data);
	}

	/////////////////////////
	// User
	/////////////////////////

	async batch_userData()
	{
		if (!this.initialized)
		{
			await this.batch_init();
		}
		if (!this.initialized
			|| DApp.instance.account === null)
		{
			return;
		}

		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_userData(),
			(o, r) => o.processRequest_userData(r),
			false,
			"[Nodes] batch user",
			"Nodes: user"
		);
	}

	makeRequest_userData()
	{
		return [
			this.makeMultiCall(
			{
				allocPoints: { function: "allocPoints", parameters: [DApp.instance.account] },
				dayDripEstimate: { function: "getDayDripEstimate", parameters: [DApp.instance.account] },
				userInfo: {function: "users", parameters: [DApp.instance.account] },
				userNodes: { function: "getNodes", parameters: [DApp.instance.account] },
			}),
			this.makeMultiCallV2(
			{
				//userPending: { function: "getTotalRewards", parameters: [DApp.instance.account] },
				userClaimedV2: { function: "claimed", parameters: [DApp.instance.account] }
			})
		];
	}

	async processRequest_userData(_data)
	{
		this.userAllocPoints = MLWeb3.toBN(_data.allocPoints);
		this.dayDrip = MLWeb3.toBN(_data.dayDripEstimate);
		//this.userPending = MLWeb3.toBN(_data.userPending);
		this.userNodes = _data.userNodes;
		this.userDeposit = MLWeb3.toBN(_data.userInfo.total_deposits);
		this.userClaimed = MLWeb3.toBN(_data.userInfo.total_claims).add(MLWeb3.toBN(_data.userClaimedV2));

		//complete
		this.initializedUser = true;
		this.dispatchEvent(ModuleEvents.user);
	}

	/////////////////////////
	// Transactions
	/////////////////////////

	create(_tier, _count, _token)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.create(
				_tier,
                _count,
				_token
			),
            this.debugErrorString("deposit"),
			`Create Node`
		);
    }

	claim()
	{
		const con = this.getContractV2(true);
        return new Web3Transaction(
            con.claim(),
            this.debugErrorString("claim"),
            `Claim Nodes`
		);
	}

	////////////////////////////////////
}

export default Nodes;