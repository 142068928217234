import { useState, useEffect, useRef } from "react";

//components{}
import {
	Flex,
	Button,
	Modal,
	InputTokenAmount_Balance
} from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//module
import {
	useMasterChef
} from "src/dApp/modules/Module_MasterChef";

const ModalDepositToVault = (props) => {
	//context
	const dApp = useDApp();
	const chef = useMasterChef();

	//state
	const [show, setShow] = useState(() => props.show);
	const [depositAmount, setdepositAmount] = useState("0");

	//functions

	//handler
	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		} else {
			setShow(false);
		}
	};

	//effect
	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	return (
		<Modal
			show={show}
			allowBig={true}
			header={`Deposit ${props.farm.name}`}
			footer={
				<Button onClick={() => {
					if (!depositAmount) return;
					chef.deposit(
						props.farm.id,
						MLWeb3.convertFloatString_BN(depositAmount, props.farm.depositToken))
						.send()
				}
				}
				>
					Deposit
				</Button>
			}
			onClose={() => handleClose()}
		>
			<Flex>
				<InputTokenAmount_Balance
					token={props.farm.depositToken}
					allowTokenSelection={true}
					value={depositAmount}
					onChange={(event, value) => setdepositAmount(value)}
				/>
			</Flex>
		</Modal>
	);
};

export default ModalDepositToVault;
