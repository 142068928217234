import { configUI as configUI_sdk } from "@MoonLabsDev/dapp-sdk-lib";

//theme
import Theme from "../../themes/GoldMint/Theme.module.css";

const configUI =
{
	...configUI_sdk,

    theme: Theme
};

export default configUI;