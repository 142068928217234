import { useState, useEffect } from "react";

//components
import {
	Flex,
	Button,
	Modal,
} from "@MoonLabsDev/dapp-core-lib";
import InputTokenAmount_Foundry from "src/dApp/components/Tomb/InputTokenAmount_Foundry.js";

//module
import {
	useTomb
} from "src/dApp/modules/Module_Tomb";
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

const ModalFoundryBonds = (props) => {
	//context
	const tomb = useTomb();

	//state
	const [show, setShow] = useState(() => props.show);
	const [amount, setAmount] = useState("0");

	//functions

	const handleTransaction = () => {
		if (!amount) return;
		if (!!props.redeem) {
			tomb.redeemBonds(
				MLWeb3.convertFloatString_BN(amount, tomb.bondToken))
				.trySend()
		} else {
			tomb.buyBonds(
				MLWeb3.convertFloatString_BN(amount, tomb.token))
				.trySend()
		}
	}

	//handler
	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		} else {
			setShow(false);
		}
	};

	//effect
	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	return (
		<Modal
			show={show}
			allowBig={true}
			header={`Withdraw ${tomb.shareToken.getFullName()}`}
			footer={
				<Button
					disabled={!amount}
					onClick={() => handleTransaction()}
				>
					{!!props.redeem ? "Redeem" : "Buy"}
				</Button>
			}
			onClose={() => handleClose()}
		>
			<Flex>
				<InputTokenAmount_Foundry
					value={amount}
					redeem={props.redeem}
					onChange={(e, v) => setAmount(v)}
				/>
			</Flex>
		</Modal>
	);
};

export default ModalFoundryBonds;
