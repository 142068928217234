//framework
import { DApp, MLWeb3, MLMultiCall, Web3Transaction, MLUtils  } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_MasterChef";

//contracts
import ABI_MasterChef from "../abi/MasterChef";

class MasterChef {
	////////////////////////////////////

	constructor(_data) {
		this.initialized = false;
		this.initializedData = false;
		this.initializedUser = false;

		//base values
		this.address = _data.address;
		this.functionNames = {
			pending: _data.functionNames?.pending || "",
			rewardPerSecond: _data.functionNames?.rewardPerSecond || "",
		};

		//data
		this.rewardTokenAddress = _data.reward;
		this.farms = [];
		this.rewardPerSecond = MLWeb3.toBN(0);
		this.totalAllocPoint = MLWeb3.toBN(0);
		this.tvl = MLWeb3.toBN(0);

		//tokens
		this.rewardToken = DApp.instance.findToken(this.rewardTokenAddress);
	}

	////////////////////////////////////

	debugErrorString(_text) {
		return `MasterChef failed at: ${_text}`;
	}

	getContract(_user) {
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_MasterChef, this.address).methods;
	}

	makeMultiCall(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.address,
			ABI_MasterChef,
			_calls
		);
	}

	dispatchEvent(_name, _farmID) {
		MLUtils.dispatchEvent(_name, {
			detail: {
				...(_farmID !== undefined && { farm: _farmID }),
			},
		});
	}

	/////////////////////////
	// Data
	/////////////////////////

	async batch_init() {
		if (this.initialized) {
			return;
		}
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_init(),
			(o, r) => o.processRequest_init(r),
			false,
			"[MasterChef] batch init",
			"MasterChef: init"
		);
		await DApp.instance.batchCall(
			this.farms,
			(o) => this.makeRequest_initFarm(o),
			(o, r) => this.processRequest_initFarm(o, r),
			false,
			"[MasterChef] batch init farms",
			"MasterChef: init farms"
		);
	}

	makeRequest_init() {
		return [
			this.makeMultiCall({
				rewardPerSecond: {
					function: this.functionNames.rewardPerSecond,
				},
				totalAllocPoint: { function: "totalAllocPoint" },
			}),
		];
	}

	async processRequest_init(_data) {
		this.rewardPerSecond = MLWeb3.toBN(_data.rewardPerSecond);
		this.totalAllocPoint = MLWeb3.toBN(_data.totalAllocPoint);

		//complete
		this.dispatchEvent(ModuleEvents.init);
	}

	makeRequest_initFarm(_farm) {
		return [
			this.makeMultiCall({
				farmInfo: { function: "poolInfo", parameters: [_farm.id] },
			}),
			_farm.depositToken.makeMultiCall({
				totalDeposit: {
					function: "balanceOf",
					parameters: [this.address],
				},
			}),
		];
	}

	async processRequest_initFarm(_farm, _data) {
		_farm.totalDeposit = MLWeb3.toBN(_data.totalDeposit);
		_farm.allocPoint = MLWeb3.toBN(_data.farmInfo.allocPoint);

		//process
		_farm.rewardPerSecond = this.rewardPerSecond
			.mul(_farm.allocPoint)
			.div(this.totalAllocPoint);
		_farm.rewardPerYear = _farm.rewardPerSecond.mul(
			MLWeb3.toBN(60 * 60 * 24 * 365)
		);
		_farm.rewardUSDPerYear = this.rewardToken.getPriceUSDForAmount(
			_farm.rewardPerYear
		);

		//complete
		this.initialized = true;
		this.dispatchEvent(ModuleEvents.data, _farm.id);
	}

	/////////////////////////
	// Data
	/////////////////////////

	async batch_data() {
		if (!this.initialized) {
			await this.batch_init();
		}
		if (!this.initialized) {
			return;
		}

		await DApp.instance.batchCall(
			this.farms,
			(o) => this.makeRequest_data(o),
			(o, r) => this.processRequest_data(o, r),
			false,
			"[MasterChef] batch data",
			"MasterChef: data"
		);

		//process
		this.tvl = MLWeb3.toBN(0);
		this.farms.forEach((f) => (this.tvl = this.tvl.add(f.totalDepositUSD)));
	}

	makeRequest_data(_farm) {
		return [
			this.makeMultiCall({
				poolInfo: { function: "poolInfo", parameters: [_farm.id] },
			}),
			_farm.depositToken.makeMultiCall({
				totalDeposit: {
					function: "balanceOf",
					parameters: [this.address],
				},
			}),
		];
	}

	async processRequest_data(_farm, _data) {
		_farm.totalDeposit = MLWeb3.toBN(_data.totalDeposit);

		//process
		_farm.rewardUSDPerYear = this.rewardToken.getPriceUSDForAmount(
			_farm.rewardPerYear
		);
		_farm.totalDepositUSD = _farm.depositToken.getPriceUSDForAmount(
			_farm.totalDeposit
		);
		_farm.apr = MLWeb3.getPercent(_farm.rewardUSDPerYear, _farm.totalDepositUSD);
		_farm.dailyAPR = _farm.apr / 365;

		//complete
		this.initializedData = true;
		this.dispatchEvent(ModuleEvents.data);
	}

	/////////////////////////
	// User
	/////////////////////////

	async batch_userData() {
		if (!this.initialized) {
			await this.batch_init();
		}
		if (!this.initialized || DApp.instance.account === null) {
			return;
		}

		await DApp.instance.batchCall(
			this.farms,
			(o) => this.makeRequest_userData(o),
			(o, r) => this.processRequest_userData(o, r),
			false,
			"[MasterChef] batch user",
			"MasterChef: user"
		);
	}

	makeRequest_userData(_farm) {
		return [
			this.makeMultiCall({
				pending: {
					function: this.functionNames.pending,
					parameters: [_farm.id, DApp.instance.account],
				},
				userInfo: {
					function: "userInfo",
					parameters: [_farm.id, DApp.instance.account],
				},
			}),
		];
	}

	async processRequest_userData(_farm, _data) {
		_farm.userPending = MLWeb3.toBN(_data.pending);
		_farm.userDeposit = MLWeb3.toBN(_data.userInfo.amount);

		//complete
		this.initializedUser = true;
		this.dispatchEvent(ModuleEvents.user, _farm.id);
	}

	/////////////////////////
	// Helper
	/////////////////////////

	findFarm(_id) {
		return this.farms.find((p) => p.id === _id) || null;
	}

	addFarm(_id, _name, _depositTokenAddress) {
		if (this.findFarm(_id) !== null) {
			return;
		}

		const f = {
			id: _id,
			name: _name,
			depositTokenAddress: _depositTokenAddress,
			depositToken: DApp.instance.findToken(_depositTokenAddress),

			totalDeposit: MLWeb3.toBN(0),
			totalDepositUSD: MLWeb3.toBN(0),
			allocPoint: MLWeb3.toBN(0),
			rewardPerSecond: MLWeb3.toBN(0),
			rewardPerYear: MLWeb3.toBN(0),
			rewardUSDPerYear: MLWeb3.toBN(0),
			apr: 0,
			dailyAPR: 0,

			userDeposit: MLWeb3.toBN(0),
			userPending: MLWeb3.toBN(0),
		};
		f.depositToken.checkApproved(this.address);
		this.farms.push(f);
	}

	/////////////////////////
	// Transactions
	/////////////////////////

	deposit(_farmID, _amount) {
		const con = this.getContract(true);
		return new Web3Transaction(
			con.deposit(_farmID, _amount),
			this.debugErrorString("deposit"),
			`Deposit Pool [${_farmID}]`
		);
	}

	withdraw(_farmID, _amount) {
		const con = this.getContract(true);
		return new Web3Transaction(
			con.withdraw(_farmID, _amount),
			this.debugErrorString("withdraw"),
			`Withdraw Pool [${_farmID}]`
		);
	}

	claim(_farmID) {
		const con = this.getContract(true);
		return new Web3Transaction(
			con.withdraw(_farmID, MLWeb3.toBN(0)),
			this.debugErrorString("claim"),
			`Claim Pool [${_farmID}]`
		);
	}

	////////////////////////////////////
}

export default MasterChef;
