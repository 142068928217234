import { useState, useEffect } from "react";

//components
import { InputTokenAmount } from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useTomb, ModuleEvents } from "src/dApp/modules/Module_Tomb";

const InputTokenAmount_Foundry = (props) =>
{
    //context
    const tomb = useTomb();

	//functions
	const getAvailable = () =>
	{
		return (!!props.redeem
			? tomb.redeemableBonds
			: tomb.availableBonds
		);
	};

	//state
    const [available, setAvailable] = useState(() => getAvailable());

    //effects
    useEffect(() =>
    {
        setAvailable(getAvailable());
    }, [props.value, props.redeem]);
    useEventSubscription(ModuleEvents.user, () => setAvailable(getAvailable()));

    return (
        <InputTokenAmount
            token={(!!props.redeem ? tomb.bondToken : tomb.token)}
            value={props.value}
            showAvailable={true}
            available={available}
            availableText={"Balance"}
            showMax={props.showMax}
            showInWallet={props.showInWallet}
            allowTokenSelection={false}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
        />
    );
};

export default InputTokenAmount_Foundry;