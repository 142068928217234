//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import MasterChef from "../classes/MasterChef";

export const ModuleEvents = {
	init: "masterchef_init",
	data: "masterchef_data",
	user: "masterchef_user",
};

export class Module_MasterChef extends DAppModule
{
	static moduleName = "masterChef";
	constructor()
	{
		super(m => m.chef);
		this.chef = null;
	}

	async onRefreshChainData(dApp)
	{
		if (this.chef === null)
		{
			return;
		}

		//data
		await MLUtils.measureTime(`TM: MasterChef => Data`, async () =>
		{
			await this.chef.batch_data();
		});

		//user
		await MLUtils.measureTime(`TM: MasterChef => User`, async () =>
		{
			await this.chef.batch_userData();
		});
	}

	async onLoad(dApp)
	{
		if (!Module_MasterChef.availableOnChain(dApp.currentChain))
		{
			return;
		}

		const mc = dApp.currentChain.modules.masterChef;
		dApp.log(false, `Loading MasterChef`);
		this.chef = new MasterChef(mc);
		this.setLoaded();
	}

	async init(dApp)
	{
		if (this.chef === null)
		{
			return;
		}

		//init farms
		if (this.chef.farms.length === 0)
		{
			dApp.log(false, `Loading Farms`);
			const jsonFarms = await MLUtils.fetchJSON(`${location.origin}/data/${dApp.currentChain.name}/farms.json?v=${dApp.dataVersion}`);
			for (let n = 0; n < jsonFarms.length; n++)
			{
				const f = jsonFarms[n];
				this.chef.addFarm(f.id, f.name, f.depositToken);
			}
		}
	}

	static availableOnChain(chain)
	{
		return chain?.modules?.masterChef !== undefined;
	}
}

export const useMasterChef = () => useModule(Module_MasterChef);

const currentModule = new Module_MasterChef();
export default currentModule;
