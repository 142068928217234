//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";


//classes
import Nodes from "../classes/Nodes";

export const ModuleEvents = {
	init: "nodes_init",
	data: "nodes_data",
};

export class Module_Nodes extends DAppModule {
	static moduleName = "nodes";
	constructor() {
		super(m => m.nodes);
		this.nodes = null;
	}

	async onRefreshChainData(dApp) {
		if (this.nodes === null) {
			return;
		}

		//data
		await MLUtils.measureTime(`TM: Nodes => Data`, async () => {
			await this.nodes.batch_data();
		});

		//user
		await MLUtils.measureTime(`TM: Nodes => User`, async () => {
			await this.nodes.batch_userData();
		});
	}

	async onLoad(dApp) {
		if (!Module_Nodes.availableOnChain(dApp.currentChain)) {
			return;
		}

		const node = dApp.currentChain.modules.nodes;
		dApp.log(false, `Loading Nodes`);
		this.nodes = new Nodes(node);
		this.setLoaded();
	}

	static availableOnChain(chain) {
		return chain?.modules?.nodes !== undefined;
	}
}

export const useNodes = () => useModule(Module_Nodes);

const currentModule = new Module_Nodes();
export default currentModule;