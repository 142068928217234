//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";


//classes
import Olympus from "../classes/Olympus";

export const ModuleEvents = {
  init: "olympus_init",
  data: "olympus_data",
  user: "olympus_user",
};

export class Module_Olympus extends DAppModule {
	static moduleName = "olympus";
  constructor() {
    super(m => m.olympus);
    this.olympus = null;
  }

  async onRefreshChainData(dApp) {
    if (this.olympus === null) {
      return;
    }

    //data
    await MLUtils.measureTime(`TM: Olympus => Data`, async () => {
		await this.olympus.batch_data();
	  });

	//user
	if (dApp.account !== null)
	{
		await MLUtils.measureTime(`TM: Olympus => User`, async () => {
			await this.olympus.batch_userData();
		});
	}
  }

  async onLoad(dApp) {
    if (!Module_Olympus.availableOnChain(dApp.currentChain)) {
      return;
    }

    const olym = dApp.currentChain.modules.olympus;
    const addrBond = olym.bond;
    const addrTreasury = olym.treasury;
    dApp.log(false, `Loading Olympus [${addrBond} / ${addrTreasury}]`);
    this.olympus = new Olympus(olym);
    this.setLoaded();
  }

  static availableOnChain(chain) {
    return chain?.modules?.olympus !== undefined;
  }
}

export const useOlympus = () => useModule(Module_Olympus);

const currentModule = new Module_Olympus();
export default currentModule;
