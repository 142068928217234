import { useState } from "react";

//components
import {
	Text,
	Flex,
	Grid,
	Link,
	Button,
	Card,
	CardBody,
	PageContent,
	ErrorServiceNotAtChain,
	InfoModuleLoading,
	TokenIcon,
	TemplateGrid,
	TemplateGridArea,
	ValueTokenPrice,
	ValueTokenTotalSupply,
	ValueTokenMarketCap,
	ValueTokenAmount,
	FormatFiatAmount,
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat } from "@MoonLabsDev/dapp-core-lib";
import {  useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import {
	useTomb,
	Module_Tomb
} from "src/dApp/modules/Module_Tomb";
import {
	useMasterChef,
} from "src/dApp/modules/Module_MasterChef";
import {
	useNodes,
	ModuleEvents
} from "src/dApp/modules/Module_Nodes";

const TokenCard = (props) => {
	//context
	const tomb = useTomb();

	return (
		<Card
			style={{
				flexGrow: 1,
				textAlign: "center",
			}}
		>
			<CardBody>
				<Grid>
					<Flex>
						<TokenIcon
							token={props.token}
							style={{
								width: "75px",
								height: "75px",
							}}
						/>
					</Flex>

					<Text size={0} color={3}>
						{props.token.symbol}
					</Text>

					<Text size={0} color={2}>
						Current Price
					</Text>

					<Text size={1} color={1}>
						{MLFormat.smartFormatToken(
							props.price,
							tomb.peggedToken
						)}{" "}
						{tomb.peggedToken.symbol}
					</Text>

					<Text size={0} color={2}>
						<Flex>
							<ValueTokenPrice token={props.token} smart={true} />{" "}
							/ {props.token.symbol}
						</Flex>
					</Text>

					<Text size={-1} color={2}>
						<Flex>
							Market Cap:{" "}
							<ValueTokenMarketCap token={props.token} />
						</Flex>
					</Text>

					<Text size={-1} color={2}>
						<Flex>
							Circulating Supply:{" "}
							<ValueTokenAmount
								token={props.token}
								value={props.circulatingSupply}
								precision={0}
								load={true}
								additionalLoad={tomb.initializedData}
							/>
						</Flex>
					</Text>

					<Text size={-1} color={2}>
						<Flex>
							Total Supply:{" "}
							<ValueTokenTotalSupply
								token={props.token}
								precision={0}
							/>
						</Flex>
					</Text>
				</Grid>
			</CardBody>
		</Card>
	);
};

const WelcomeCard = () => {
	return (
		<Card
			style={{
				textAlign: "center",
			}}
		>
			<CardBody>
				<Text color={3}>WELCOME TO GOLDMINT</Text>
				<br />
				<Text size={-1} color={2}>
					GoldMint Finance is an algocoin seigniorage protocol on the
					Ethereum blockchain.
					<br />
					The protocol's goal is stability, transparency, security,
					and longevity. GoldMint is trying to bridge the gap to bring
					commodities and DEFI together and provide a way for people
					to participate in the trading and farming of crypto while
					also having the exposure to Gold.
					<br />
				</Text>
				<Text size={-1} color={1}>
					The GoldMint Finance team aims to make this protocol the
					"Gold Standard of Seigniorage".
					<br />
				</Text>
				<Text size={-1} color={2}>
					<Link size={-1} href="https://linktr.ee/goldmint">
						LinkTree
					</Link>{" "}
					to find out more!
				</Text>
			</CardBody>
		</Card>
	);
};

const HomeContent = () => {
	//context
	const tomb = useTomb();
	const chef = useMasterChef();
	const nodes = useNodes();

	//state
	const [csToken, setCSToken] = useState(tomb.tokenCirculatingSupply);
	const [csBond, setCSBond] = useState(tomb.bondCirculatingSupply);
	const [csShare, setCSShare] = useState(tomb.shareCirculatingSupply);

	//handler
	const handleUpdate = () => {
		setCSToken(tomb.tokenCirculatingSupply);
		setCSBond(tomb.bondCirculatingSupply);
		setCSShare(tomb.shareCirculatingSupply);
	};

	//effect
	useEventSubscription(ModuleEvents.data, handleUpdate);

	return (
		<TemplateGrid
			templateColumns={300}
			templateAreas={`'logo' 'welcome' 'tvl' 'buttons' 'tokens'`}
			responsive={{
				md: {
					templateAreas: `'logo welcome welcome'
										'tvl buttons buttons'
										'tokens tokens tokens'`,
				},
			}}
			style={{
				maxWidth: "90vw",
				margin: "auto",
			}}
		>
			<TemplateGridArea area="logo">
				<Flex>
					<TokenIcon style={{ height: "250px" }} token={tomb.token} />
				</Flex>
			</TemplateGridArea>

			<TemplateGridArea area="welcome">
				<WelcomeCard />
			</TemplateGridArea>

			<TemplateGridArea area="tvl">
				<Card style={{ textAlign: "center" }}>
					<CardBody>
						<Grid>
							<Text color={3}>TOTAL VALUE LOCKED</Text>
							<Text color={2}>
								<FormatFiatAmount
									value={chef.tvl.add(nodes.tvl)}
									shorten={true}
									precision={0}
								/>
							</Text>
						</Grid>
					</CardBody>
				</Card>
			</TemplateGridArea>

			<TemplateGridArea area="buttons">
				<Card>
					<CardBody>
						<Flex>
							<Button href="https://legacy.goldmint.finance">
								Legacy UI
							</Button>
						</Flex>
					</CardBody>
				</Card>
			</TemplateGridArea>

			<TemplateGridArea area="tokens">
				<Flex>
					<TokenCard
						token={tomb.token}
						price={tomb.tokenPricePegged}
						circulatingSupply={csToken}
					/>
					<TokenCard
						token={tomb.shareToken}
						price={tomb.sharePricePegged}
						circulatingSupply={csShare}
					/>
					<TokenCard
						token={tomb.bondToken}
						price={tomb.bondPricePegged}
						circulatingSupply={csBond}
					/>
				</Flex>
			</TemplateGridArea>
		</TemplateGrid>
	);
};

export default () => {
	return (
		<>
			<PageContent>
				<ErrorServiceNotAtChain module="tomb">
					<InfoModuleLoading module="tomb">
						<HomeContent />
					</InfoModuleLoading>
				</ErrorServiceNotAtChain>
			</PageContent>
		</>
	);
}
