//dApp
import { configPage as config } from "@MoonLabsDev/dapp-sdk-lib";

//modules
import
{
	Module_Tomb,
	Module_MasterChef,
	Module_Nodes,
	Module_Olympus
} from "../modules";

//pages (test)
import
{
	Page_debugInfo,
	Page_test_general,
	Page_test_cards,
	Page_test_charts,
	Page_test_modals
} from "@MoonLabsDev/dapp-sdk-lib";

//pages
import
{
	Page_home,
	Page_olympus,
	Page_mint,
	Page_foundry,
	Page_nodes,
	Page_vault
} from "../../pages";

const configPage =
{
	...config,

    title: "GoldMint",
    description: "GoldMint is an algorithmic stablecoin on Ethereum. GLDM is pegged to PAXG.",
	logo: "/logo.png",
    themeColor: "#1e1e1e",
	backgroundImage: "/background.png",
	devMode: false,
	socials:
	{
		discord: "",
		telegram: "",
		github: "",
		gitbook: "",
		twitter: ""
	},
    modules:
    [
        Module_Tomb,
		Module_MasterChef,
		Module_Nodes,
		Module_Olympus
    ],
	format:
	{
		thousandsSeperator: ","
	},
    preconnect:
    [
        { link: "https://fonts.googleapis.com" },
        { link: "https://fonts.gstatic.com", crossOrigin: true }
    ],
	fonts:
	[
		"https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap",
		"https://fonts.googleapis.com/css2?family=Rubik&display=swap",
	],
    pages:
	[
		{ path: "home", page: Page_home },
		{ path: "debugInfo", page: Page_debugInfo },
		{ path: "earn/nodes", page: Page_nodes },
		{ path: "earn/vaults", page: Page_vault },
		{ path: "earn/mint", page: Page_mint },
		{ path: "bond/foundry", page: Page_foundry },
		{ path: "bond/olympus", page: Page_olympus },

		{ path: "tests/general", page: Page_test_general },
		{ path: "tests/cards", page: Page_test_cards },
		{ path: "tests/charts", page: Page_test_charts },
		{ path: "tests/modals", page: Page_test_modals },
	]
};

export default configPage;