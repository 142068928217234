import { useState } from "react";

//components
import {
	Text,
	Flex,
	Grid,
	TokenIcon,
	Card,
	CardBody,
	CardFooter,
	PageHeader,
	PageContent,
	ErrorServiceNotAtChain,
	InfoModuleLoading,
	Button,
	ValueCountdown,
	FormatPercent,
	ValueTokenAmount,
	ValueTokenFiatAmount,
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat } from "@MoonLabsDev/dapp-core-lib";
import ModalDepositToBoardroom from "src/dApp/components/Tomb/ModalDepositToBoardroom.js";
import ModalWithdrawFromBoardroom from "src/dApp/components/Tomb/ModalWithdrawFromBoardroom.js";

//hooks
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//module
import {
	useTomb,
	TombProvider,
	Module_Tomb,
	ModuleEvents,
} from "src/dApp/modules/Module_Tomb";

const flexColumn = { display: "flex", flexDirection: "column" };
const contentCenter = {
	display: "flex",
	alignItems: "center",
};
const dataText = {
	textAlign: "center",
	padding: "5px 0px",
};
const iconPosition = {
	height: "50px",
	width: "50px",
	margin: "25px 0px",
};

const wrap = { display: "flex", flexWrap: "wrap" };

const InfoCard = (props) => {
	return (
		<Card style={{ width: "200px" }}>
			<CardBody>
				<Grid>
					<Text
						color={1}
						align="center"
					>
						{props.title}
					</Text>
					<Text
						color={2}
						align="center"
					>
						{props.children}
					</Text>
				</Grid>
			</CardBody>
		</Card>
	);
}

const TombContent = () => {
	//context
	const tomb = useTomb();

	//function
	const getTombData = () => {
		return {
			epoch: tomb.epoch,
			nextEpoch: tomb.nextEpoch,
			twap: tomb.tokenPricePegged,
			apr: tomb.shareAPR,
			totalStaked: tomb.totalStakedShares,
			userStaked: tomb.stakedShares,
			userPending: tomb.pendingRewards
		};
	};

	//state
	const [data, setData] = useState(getTombData());
	const [showDepositModal, setShowDepositModal] = useState(() => false);
	const [showWithdrawModal, setShowWithdrawModal] = useState(() => false);

	//handler
	const handleClaim = () => {
		if (tomb.pendingRewards.isZero()) return;
		tomb.claimShareRewards().send();
	};
	const handleApprove = () => {
		tomb.shareToken.approve(tomb.boardroomAddress).send();
	};
	const handleExit = () => {
		tomb.exitShares().trySend();
	}

	const generateDepositModalButton = (_setShow, _modal) => {
		return (
			<>
				<Button onClick={() => _setShow(true)}>
					+
				</Button>
				{_modal}
			</>
		);
	};

	const generateWithdrawModalButton = (_setShow, _modal) => {
		return (
			<>
				<Button onClick={() => _setShow(true)}>
					-
				</Button>
				{_modal}
			</>
		);
	};

	//effect
	useEventSubscription([ModuleEvents.data, ModuleEvents.user], () => setData(getTombData()));

	return (
		<Flex style={flexColumn}>
			<Grid cols={5} style={wrap}>
				<InfoCard
					title="Next Epoch"
				>
					<ValueCountdown
						target={data.nextEpoch}
					/>
				</InfoCard>

				<InfoCard
					title="Current Epoch"
				>
					{data.epoch}
				</InfoCard>

				<InfoCard
					title={`${tomb.token.symbol} peg (TWAP)`}
				>
					{MLFormat.smartFormatToken(
						data.twap,
						tomb.peggedToken
					)}{" "}
					{tomb.peggedToken.symbol}
				</InfoCard>

				<InfoCard
					title="APR"
				>
					<FormatPercent
						value={data.apr}
						smart={true}
					/>
				</InfoCard>

				<InfoCard
					title={`${tomb.shareToken.symbol} staked`}
				>
					<ValueTokenAmount
						token={tomb.shareToken}
						value={data.totalStaked}
						precision={2}
					/>
				</InfoCard>
			</Grid>
			<Grid cols={2} style={{ width: "50%", ...contentCenter, ...wrap }}>
				<Card style={{ width: "250px" }}>
					<CardBody>
						<div style={{ ...flexColumn, ...contentCenter }}>
							<TokenIcon
								token={tomb.shareToken}
								style={iconPosition}
							/>
							<Text size={2} style={dataText}>
								<ValueTokenAmount
									token={tomb.shareToken}
									value={data.userStaked}
									smart={true}
								/>
							</Text>
							<Text size={-1} style={dataText}>
								<ValueTokenFiatAmount
									token={tomb.shareToken}
									value={data.userStaked}
									precision={2}
								/>
							</Text>
							<Text style={dataText}>
								{tomb.shareToken.symbol} staked
							</Text>
						</div>
					</CardBody>
					<CardFooter>
						{tomb.shareToken.checkApproved(tomb.boardroomAddress)
							? (
								<Grid>
									<></>
									<Grid cols={2}>
										{generateWithdrawModalButton(
											setShowWithdrawModal,
											<ModalWithdrawFromBoardroom
												show={showWithdrawModal}
												onClose={() => setShowWithdrawModal(false)}
											/>)}
										{generateDepositModalButton(
											setShowDepositModal,
											<ModalDepositToBoardroom
												show={showDepositModal}
												onClose={() => setShowDepositModal(false)}
											/>)}
									</Grid>
									<></>
								</Grid>
							)
							: (
								<Grid>
									<Button
										onClick={() => handleApprove()}
									>
										Approve
									</Button>
								</Grid>
							)
						}
					</CardFooter>
				</Card>

				<Card style={{ width: "250px" }}>
					<CardBody>
						<div style={{ ...flexColumn, ...contentCenter }}>
							<TokenIcon
								token={tomb.token}
								style={iconPosition}
							/>
							<Text size={2} style={dataText}>
								<ValueTokenAmount
									token={tomb.token}
									value={data.userPending}
									smart={true}
								/>
							</Text>
							<Text size={-1} style={dataText}>
								<ValueTokenFiatAmount
									token={tomb.token}
									value={data.userPending}
									precision={2}
								/>
							</Text>
							<Text style={dataText}>
								{tomb.token.symbol} earned
							</Text>
						</div>
					</CardBody>
					<CardFooter>
						<Grid>
							<Button
								disabled={tomb.pendingRewards.isZero()}
								onClick={() => handleClaim()}
							>
								Claim
							</Button>
						</Grid>
					</CardFooter>
				</Card>
			</Grid>
			<Button
				style={{ marginTop: "8px" }}
				onClick={() => handleExit()}>Claim and whithdraw</Button>
		</Flex>
	);
};

export default () => {
	return (
		<>
			<PageHeader title="Boardroom">
				Stake SGLDM to earn GLDM when over peg
			</PageHeader>

			<PageContent>
				<ErrorServiceNotAtChain module="tomb">
					<InfoModuleLoading module="tomb">
						<TombContent />
					</InfoModuleLoading>
				</ErrorServiceNotAtChain>
			</PageContent>
		</>
	);
}
