import { useState, useEffect } from "react";

//components
import { InputTokenAmount } from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMasterChef, ModuleEvents } from "src/dApp/modules/Module_MasterChef";

const InputTokenAmount_FarmBalance = (props) => {
    //context
    const chef = useMasterChef();

    //const state
    const [farm] = useState(chef.findFarm(props.farmID));

    //functions
    const getAvailable = () => {
        return (farm?.userDeposit || MLWeb3.toBN(0));
    };

    //state
    const [available, setAvailable] = useState(() => getAvailable());

    //handler
    const handleBalanceUpdate = (data) => {
        if (data.detail.farmID !== farm?.id) {
            return;
        }

        setAvailable(getAvailable());
    };

    //effects
    useEffect(() => {
        setAvailable(getAvailable());
    }, [props.value, props.token]);
    useEventSubscription(ModuleEvents.user, handleBalanceUpdate, [farm]);

    return (
        <InputTokenAmount
            token={props.token}
            value={props.value}
            showAvailable={true}
            available={available}
            availableText={"Balance"}
            showMax={props.showMax}
            showInWallet={props.showInWallet}
            allowTokenSelection={false}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
        />
    );
};

export default InputTokenAmount_FarmBalance;