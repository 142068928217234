const configChains =
{
    defaultChain: 1,
    chains:
    [
		{
			id: 1
		}
    ]
};

export default configChains;