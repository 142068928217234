//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction, MLUtils } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_Tomb";

//contracts
import ABI_Boardroom from "../abi/TombBoardroom";
import ABI_Treasury from "../abi/TombTreasury";
import ABI_RewardPool from "../abi/TombRewardPool";

class Tomb {
	////////////////////////////////////

	constructor(_data) {
		this.initialized = false;
		this.initializedData = false;
		this.initializedUser = false;

		//base values
		this.addressToken = _data.token;
		this.addressPeggedToken = _data.pegged;
		this.addressBondToken = _data.bond;
		this.addressShareToken = _data.share;
		this.boardroomAddress = _data.boardroom;
		this.treasuryAddress = _data.treasury;
		this.rewardPools = _data.rewardPools;
		this.epochLength = _data.epochLength; //epoch length in seconds

		//tokens
		this.token = DApp.instance.findToken(this.addressToken);
		this.peggedToken = DApp.instance.findToken(this.addressPeggedToken);
		this.bondToken = DApp.instance.findToken(this.addressBondToken);
		this.shareToken = DApp.instance.findToken(this.addressShareToken);
		this.lpToken = null;

		//info
		this.bondRatio = MLWeb3.toBN(0);
		this.tokenPricePegged = MLWeb3.toBN(0);
		this.bondPricePegged = MLWeb3.toBN(0);
		this.sharePricePegged = MLWeb3.toBN(0);
		this.bondRedeemPrice = MLWeb3.toBN(0);
		this.epoch = 0;
		this.nextEpoch = null;
		this.totalStakedShares = MLWeb3.toBN(0);
		this.boardroomSnapshot = 0;
		this.totalEpochRewards = MLWeb3.toBN(0);
		this.shareAPR = 0;
		this.availableBonds = MLWeb3.toBN(0);
		this.redeemableBonds = MLWeb3.toBN(0);
		this.priceInTWAP = MLWeb3.toBN(0);

		//user
		this.stakedShares = MLWeb3.toBN(0);
		this.pendingRewards = MLWeb3.toBN(0);
		this.canClaimRewards = false;
		this.canWithdraw = false;

		//supply
		this.tokenRewardBalance = MLWeb3.toBN(0);
		this.bondRewardBalance = MLWeb3.toBN(0);
		this.shareRewardBalance = MLWeb3.toBN(0);
		this.tokenCirculatingSupply = MLWeb3.toBN(0);
		this.bondCirculatingSupply = MLWeb3.toBN(0);
		this.shareCirculatingSupply = MLWeb3.toBN(0);

		//preload
		this.shareToken.checkApproved(this.boardroomAddress);
	}

	////////////////////////////////////

	debugErrorString(_text) {
		return `Tomb failed at: ${_text}`;
	}

	getContract(_user) {
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_Foundry, this.foundryAddress).methods;
	}

	getContractTreasury(_user) {
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_Treasury, this.treasuryAddress).methods;
	}

	getContractBoardroom(_user) {
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_Boardroom, this.boardroomAddress).methods;
	}

	getContractRewardPool(_user) {
		const con = DApp.instance.selectWeb3Connection(_user);
		return new con.eth.Contract(ABI_TombRewardPool, this.rewardPoolAddress).methods;
	}

	makeMultiCall(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.foundryAddress,
			ABI_Foundry,
			_calls
		);
	}

	makeMultiCallTreasury(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.treasuryAddress,
			ABI_Treasury,
			_calls
		);
	}

	makeMultiCallBoardroom(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.boardroomAddress,
			ABI_Boardroom,
			_calls
		);
	}

	makeMultiCallRewardPool(_calls) {
		return MLMultiCall.makeMultiCallContext(
			this.rewardPoolAddress,
			ABI_TombRewardPool,
			_calls
		);
	}

	dispatchEvent(_name) {
		MLUtils.dispatchEvent(_name);
	}

	/////////////////////////
	// Init
	/////////////////////////

	async batch_init() {
		if (this.initialized) {
			return;
		}
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_init(),
			(o, r) => o.processRequest_init(r),
			false,
			"[Tomb] batch init",
			"Tomb: init"
		);
	}

	makeRequest_init() {
		return [
			this.makeMultiCallBoardroom(
				{
					boardroomSnapshot: { function: "latestSnapshotIndex" }
				})
		];
	}

	async processRequest_init(_data) {
		this.boardroomSnapshot = parseInt(_data.boardroomSnapshot);

		//complete
		this.initialized = true;
		this.dispatchEvent(ModuleEvents.init);
	}

	/////////////////////////
	// Data
	/////////////////////////

	async batch_data() {
		await this.batch_init();
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_data(),
			(o, r) => o.processRequest_data(r),
			false,
			"[Tomb] batch data",
			"Tomb: data"
		);
	}

	makeRequest_data() {
		return [
			this.token.makeMultiCall(
				{
					tokenRewardBalance: { function: "balanceOf", parameters: [this.rewardPools.genesis], },
				}),
			this.bondToken.makeMultiCall(
				{
					bondRewardBalance: { function: "balanceOf", parameters: [this.rewardPools.rewardPool], },
				}),
			this.shareToken.makeMultiCall(
				{
					shareRewardBalance: { function: "balanceOf", parameters: [this.rewardPools.rewardPool], },
				}),
			this.makeMultiCallTreasury(
				{
					epoch: { function: "epoch" },
					nextEpoch: { function: "nextEpochPoint" },
					nextEpochPoint: { function: "nextEpochPoint" },
					bondPremiumRate: { function: "getBondPremiumRate" },
					priceInTWAP: { function: "getGldmUpdatedPrice" },
					availableBonds: { function: "getBurnableGldmLeft" },
					redeemableBonds: { function: "getRedeemableBonds" }
				}),
			this.makeMultiCallBoardroom(
				{
					totalStakedShares: { function: "totalSupply" },
					boardroomSnapshot: { function: "latestSnapshotIndex" },
					boardroomHistory: { function: "boardroomHistory", parameters: [MLWeb3.toBN(this.boardroomSnapshot).toString(10)] }
				})
		];
	}

	async processRequest_data(_data) {
		this.tokenRewardBalance = MLWeb3.toBN(_data.tokenRewardBalance);
		this.bondRewardBalance = MLWeb3.toBN(_data.bondRewardBalance);
		this.shareRewardBalance = MLWeb3.toBN(_data.shareRewardBalance);
		this.epoch = parseInt(_data.epoch);
		this.nextEpoch = (_data.nextEpoch === "0" ? null : new Date(parseInt(_data.nextEpoch) * 1000));
		this.totalStakedShares = MLWeb3.toBN(_data.totalStakedShares);
		this.boardroomSnapshot = parseInt(_data.boardroomSnapshot);
		this.totalEpochRewards = MLWeb3.toBN(_data.boardroomHistory.rewardReceived);
		this.availableBonds = MLWeb3.toBN(_data.availableBonds);
		this.redeemableBonds = MLWeb3.toBN(_data.redeemableBonds);
		this.priceInTWAP = MLWeb3.toBN(_data.priceInTWAP);

		//process
		if (this.lpToken === null) {
			const r = DApp.instance.findRouter(this.token.router);
			this.lpToken = r.lookupPair(this.token, this.peggedToken);
		}
		this.bondRatio = this.lpToken.getLPTokenRatio(this.peggedToken, this.token.one);
		this.tokenPricePegged = this.token.getPriceInTokenForAmount(this.peggedToken, this.token.one);
		this.bondPricePegged = this.bondToken.getPriceInTokenForAmount(this.peggedToken, this.bondToken.one);
		this.sharePricePegged = this.shareToken.getPriceInTokenForAmount(this.peggedToken, this.shareToken.one);
		this.bondRedeemPrice = this.bondPricePegged.mul(this.bondPricePegged).div(this.peggedToken.one);
		this.tokenCirculatingSupply = this.token.circulatingSupply.sub(this.tokenRewardBalance);
		this.bondCirculatingSupply = this.bondToken.circulatingSupply;
		this.shareCirculatingSupply = this.shareToken.circulatingSupply.sub(this.shareRewardBalance);

		//apr
		const rewardPerYear = this.totalEpochRewards.mul(MLWeb3.toBN(365 * 24 * 60 * 60)).div(MLWeb3.toBN(this.epochLength));
		const totalStakedUSD = this.shareToken.getPriceUSDForAmount(this.totalStakedShares);
		const rewardperYearUSD = this.token.getPriceUSDForAmount(rewardPerYear);
		this.shareAPR = MLWeb3.getPercent(rewardperYearUSD, totalStakedUSD);

		//complete
		this.initializedData = true;
		this.dispatchEvent(ModuleEvents.data);
	}

	/////////////////////////
	// User
	/////////////////////////

	async batch_userData() {
		if (DApp.instance.account === null) {
			return;
		}
		await this.batch_init();
		await DApp.instance.batchCall(
			[this],
			(o) => o.makeRequest_userData(),
			(o, r) => o.processRequest_userData(r),
			false,
			"[Tomb] batch user",
			"Tomb: user"
		);
	}

	makeRequest_userData() {
		return [
			this.makeMultiCallBoardroom(
			{
				stakedShares: { function: "balanceOf", parameters: [DApp.instance.account] },
				pendingRewards: { function: "earned", parameters: [DApp.instance.account] },
				canWithdraw: { function: "canWithdraw", parameters: [DApp.instance.account] },
			})
		];
	}

	async processRequest_userData(_data) {
		this.stakedShares = MLWeb3.toBN(_data.stakedShares);
		this.pendingRewards = MLWeb3.toBN(_data.pendingRewards);
		this.canWithdraw = _data.canWithdraw;

		//complete
		this.initializedUser = true;
		this.dispatchEvent(ModuleEvents.user);
	}

	/////////////////////////
	// Transactions
	/////////////////////////

	buyBonds(_amount) {
		const con = this.getContractTreasury(true);
		return new Web3Transaction(
			con.buyBonds(
				_amount,
				this.tokenPricePegged
			),
			this.debugErrorString("buyBonds"),
			`Buy Bonds ${MLFormat.smartFormatToken(_amount, this.bondToken)}`
		);
	}

	redeemBonds(_amount) {
		const con = this.getContractTreasury(true);
		return new Web3Transaction(
			con.redeemBonds(
				_amount,
				this.tokenPricePegged
			),
			this.debugErrorString("redeemBonds"),
			`Redeem Bonds ${MLFormat.smartFormatToken(_amount, this.bondToken)}`
		);
	}

	claimShareRewards() {
		const con = this.getContractBoardroom(true);
		return new Web3Transaction(
			con.claimReward(),
			this.debugErrorString("claimShareRewards"),
			`Claim Share Rewards`
		);
	}

	withdrawShares(_amount) {
		const con = this.getContractBoardroom(true);
		return new Web3Transaction(
			con.withdraw(_amount),
			this.debugErrorString("withdrawShares"),
			`Withdraw Shares ${MLFormat.smartFormatToken(_amount, this.shareToken)}`
		);
	}

	stakeShares(_amount) {
		const con = this.getContractBoardroom(true);
		return new Web3Transaction(
			con.stake(_amount),
			this.debugErrorString("stakeShares"),
			`Stake Shares ${MLFormat.smartFormatToken(_amount, this.shareToken)}`
		);
	}

	exitShares() {
		const con = this.getContractBoardroom(true);
		return new Web3Transaction(
			con.exit(),
			this.debugErrorString("exitShares"),
			`Exit Shares`
		);
	}
	////////////////////////////////////
}

export default Tomb;