import { configMenu as defaultConfig } from "@MoonLabsDev/dapp-sdk-lib";
import configPage from "./page";

import { ToolMenu } from "../components/ToolMenu";

const configMenu =
{
	//...defaultConfig,
	toolComponent: ToolMenu,
    desktopMode:
    {
        header:
        {
            menu: "normal",
			tool: "normal",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    tabletMode:
    {
        header:
        {
            menu: "minimal",
			tool: "none",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    mobileMode:
    {
        header:
        {
            menu: "none",
			tool: "none",
            user: "app"
        },
        footer:
        {
            menu: "app",
			tool: "none",
            user: "none"
        }
    },

	desktopMenu:
	[
		{
			text: "Home",
			link: "/",
		},
		{
			text: "Earn",
			children:
			[
				{
					text: "Vaults",
					link: "/earn/vaults",
				},
				{
					text: "Mint",
					link: "/earn/mint",
				},
				{
					text: "Nodes",
					link: "/earn/nodes",
				}
			],
		},
		{
			text: "Bond",
			children:
			[
				{
					text: "Foundry",
					link: "/bond/foundry",
				},
				{
					text: "Olympus",
					link: "/bond/olympus",
				}
			],
		},
		{
			text: "Info",
			children:
			[
				{
					text: "Merch",
					link: "https://defimerchshop.com/collections/goldmint-collection",
					extern: true
				},
				{
					text: "Lite Paper",
					link: "https://gmfinance.gitbook.io/gm-litepaper/",
					extern: true
				},
				{
					text: "Docs",
					link: "https://gmfinance.gitbook.io/gm-whitepaper/main-protocol/concept",
					extern: true
				},
				{
					text: "Roadmap",
					link: "https://gmfinance.gitbook.io/gm-whitepaper/main-protocol/roadmap",
					extern: true
				},
			],
		},
		...(!!configPage.devMode
			? [
				{
					text: "Test",
					link: "/tests/general",
					children:
					[
						{
							text: "General",
							link: "/tests/general",
						},
						{
							text: "Cards",
							link: "/tests/cards",
						},
						{
							text: "Charts",
							link: "/tests/charts",
						},
						{
							text: "Modals",
							link: "/tests/modals",
						},
					],
				}
			]
			: []
		),
	],
	mobileMenu: undefined,
};

export default configMenu;