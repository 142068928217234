//components
import
{
	Flex,
	TokenPriceButton,
	GasButton,
	InfoModuleLoading
} from "@MoonLabsDev/dapp-core-lib";

//modules
import { useTomb } from "../modules/Module_Tomb";

export const ToolMenu = () =>
{
	const tomb = useTomb();

	return (
		<InfoModuleLoading module="tomb">
			<Flex>
				<TokenPriceButton
					token={tomb.token}
					hover={true}
					menu=
					{
						[
							{
								text: "Buy",
								link: "https://app.uniswap.org/#/swap?inputCurrency=0x45804880De22913dAFE09f4980848ECE6EcbAf78&outputCurrency=0x8Dbd43048e60e3c9a602de7F5f1F1c8A9C6Ffee1&chain=mainnet",
								extern: true
							},
							{
								text: "Charts",
								link: "https://dexscreener.com/ethereum/0x59b858a5e84059d166acf3dc8e8a2369385643af",
								extern: true
							}
						]
					}
				/>
				<TokenPriceButton
					token={tomb.shareToken}
					hover={true}
					menu=
					{
						[
							{
								text: "Buy",
								link: "https://app.uniswap.org/#/swap?inputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&outputCurrency=0xE61776305003d700FBC8318da97806032a3b08ED&chain=mainnet",
								extern: true
							},
							{
								text: "Charts",
								link: "https://dexscreener.com/ethereum/0xdfabf33a5c265192d6293687c08583cce5913169",
								extern: true
							}
						]
					}
				/>
				<TokenPriceButton
					token={tomb.peggedToken}
					hover={true}
					menu=
					{
						[
							{
								text: "Buy",
								link: "https://app.uniswap.org/#/swap?outputCurrency=0x45804880De22913dAFE09f4980848ECE6EcbAf78&chain=mainnet",
								extern: true
							},
							{
								text: "Charts",
								link: "https://dexscreener.com/ethereum/0x45804880De22913dAFE09f4980848ECE6EcbAf78",
								extern: true
							}
						]
					}
				/>
			</Flex>
		</InfoModuleLoading>
	);
};