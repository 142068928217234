import { useState } from "react";

//components
import {
	Text,
	Flex,
	Grid,
	TokenIcon,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	PageHeader,
	PageContent,
	ErrorServiceNotAtChain,
	InfoModuleLoading,
	Button,
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";
import ModalFoundryBonds from "src/dApp/components/Tomb/ModalFoundryBonds.js";
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//module
import {
	useTomb,
	Module_Tomb,
} from "src/dApp/modules/Module_Tomb";

const flexColumn = { display: "flex", flexDirection: "column" };
const contentCenter = {
	display: "flex",
	alignItems: "center",
};
const justifyCenter = {
	display: "flex",
	justifyContent: "center",
};
const iconPosition = {
	height: "50px",
	width: "50px",
	margin: "25px 0px",
};

const wrap = { display: "flex", flexWrap: "wrap" };

const ModalButton = (setShow, modal, children) => {
	return (
		<>
			<Button onClick={() => setShow(true)}>
				{children}
			</Button>
			{modal}
		</>
	);
};

const TombContent = (props) => {
	//context
	const dApp = useDApp();
	const tomb = useTomb();

	//functions
	const getTombData = () => {
		return {
			balance: 0, // fill with full object
		};
	};

	//state

	const [showBuyModal, setShowBuyModal] = useState(() => false);
	const [showRedeemModal, setShowRedeemModal] = useState(() => false);

	//effects
	// useEventSubscription(ModuleEvents.data, () => setTombData(getTombData()));

	return (
		<Flex style={{ width: "full" }}>
			<Grid cols={3} style={{ ...contentCenter, ...wrap }}>
				<Card style={{ width: "300px" }}>
					<CardHeader style={{ textAlign: "center" }}>
						Purchase {tomb.bondToken.symbol}
					</CardHeader>
					<CardBody>
						<Flex>
							<Grid cols={3}>
								<TokenIcon
									token={tomb.token}
									style={iconPosition}
								/>
								<Text size={2} style={{ textAlign: "center" }}>
									-
								</Text>
								<TokenIcon
									token={tomb.bondToken}
									style={iconPosition}
								/>
							</Grid>
							<Text>
								{MLWeb3.convertBN_Float(tomb.priceInTWAP) < 1.01 ?
									(
										<Text>
											{tomb.token.symbol} is under PEG
										</Text>
									) : (
										<Text>
											{`${MLWeb3.convertBN_Float(tomb.availableBonds)}	${tomb.bondToken.symbol} Available to purchase` /* burn GLDM */}
										</Text>
									)
								}
							</Text>

						</Flex>
					</CardBody>
					<CardFooter style={justifyCenter}>
						{tomb.token.checkApproved(tomb.treasuryAddress)
							? <ModalButton
									setShow={setShowBuyModal}
									modal=
									{
										<ModalFoundryBonds
											show={showBuyModal}
											redeem={false}
											onClose={() => setShowBuyModal(false)}
										/>
									}
								>
									Buy Bonds
								</ModalButton>
							: <Button onClick={() => tomb.token.approve(tomb.treasuryAddress).send()}>Approve</Button>
						}
					</CardFooter>
				</Card>
				<Flex style={flexColumn}>
					<Card style={{ width: "300px" }}>
						<CardBody>
							<Flex>
								<Text>
									💰 1 {tomb.token.symbol}
									{" "}={" "}
									{MLFormat.smartFormatToken(
										tomb.tokenPricePegged,
										tomb.peggedToken
									)}{" "}
									{tomb.peggedToken.symbol}
								</Text>
								<Text size={-1}>
									Last-Hour TWAP Price
								</Text>
							</Flex>
						</CardBody>
					</Card>
					<Card style={{ width: "300px" }}>
						<CardBody>
							<Flex>
								<Text>
									💰 1 {tomb.bondToken.symbol}
									{" "}={" "}
									{MLFormat.smartFormatToken(
										tomb.bondRedeemPrice,
										tomb.peggedToken
									)}{" "}
									{tomb.peggedToken.symbol}
								</Text>
								<Text size={-1}>
									Current Price: ({tomb.token.symbol})^2
								</Text>
							</Flex>
						</CardBody>
					</Card>
				</Flex>
				<Card style={{ width: "300px" }}>
					<CardHeader style={{ textAlign: "center" }}>
						Redeem {tomb.token.symbol}
					</CardHeader>
					<CardBody>
						<Flex>
							<Grid cols={3}>
								<TokenIcon
									token={tomb.bondToken}
									style={iconPosition}
								/>
								<Text size={2} style={{ textAlign: "center" }}>
									-
								</Text>
								<TokenIcon
									token={tomb.token}
									style={iconPosition}
								/>
							</Grid>
							<Text>
								{`${MLWeb3.convertBN_Float(tomb.redeemableBonds)} ${tomb.bondToken.symbol} Available to redeem`}
							</Text>
						</Flex>
					</CardBody>
					<CardFooter style={justifyCenter}>
						<Flex>
							{tomb.bondToken.checkApproved(tomb.treasuryAddress)
								? <ModalButton
										setShow={setShowRedeemModal}
										modal=
										{
											<ModalFoundryBonds
												show={showRedeemModal}
												redeem={true}
												onClose={() => setShowRedeemModal(false)}
											/>
										}
									>
										Redeem Bonds
									</ModalButton>
								: <Button onClick={() => tomb.bondToken.approve(tomb.treasuryAddress).send()}>Approve</Button>
							}
						</Flex>
					</CardFooter>
				</Card>
			</Grid>
		</Flex>
	);
};

export default () => {
	return (
		<>
			<PageHeader title="Foundry">
				Stake SGLDM to earn GLDM when over peg
			</PageHeader>

			<PageContent>
				<ErrorServiceNotAtChain module="tomb">
					<InfoModuleLoading module="tomb">
						<TombContent />
					</InfoModuleLoading>
				</ErrorServiceNotAtChain>
			</PageContent>
		</>
	);
}
